@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  background-color: #c6f3ff;
  font-family: 'Roboto Slab', serif;
}

.title {
  font-family: 'El Messiri', sans-serif;
  font-family: 'Inter', sans-serif;
}

.background-color {
  background-color: #f9fafb;
}

.wave {
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.wave svg {
  position: relative;
  display: block;
  width: calc(188% + 1.3px);
  height: 99px;
}

.wave .shape-fill {
  fill: #ebfbff;
}

.curve {
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.curve svg {
  position: relative;
  display: block;
  width: calc(188% + 1.3px);
  height: 99px;
}

.curve .shape-fill {
  fill: #ebfbff;
}

.cloud {
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.cloud svg {
  position: relative;
  display: block;
  width: calc(188% + 1.3px);
  height: 99px;
}

.cloud .shape-fill {
  fill: #79DCF8;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.6s ease-out;
}

.fade-in.visible {
  opacity: 1;
}

.white-bg {
  background-color: #ebfbff;
}

/* CSS */
.navbar {
  z-index: 1000;
  position: sticky;
  top: 0;
  transition: top 0.15s;
}

.navbar-hidden {
  top: -100px; /* Adjust based on the height of your navbar */
}
